import { api, ApiEndpoint } from '../index.jsx'
import * as auth from '../entry-points.jsx'

const WHERE = '/'
const schema = {}

const loginEndpoint = new ApiEndpoint(WHERE, api, schema)

const signIn = (payload) => loginEndpoint.request(auth.SIGN_IN, payload, 'post')

const logOut = (payload) => loginEndpoint.request(auth.LOGOUT, payload, 'get')

const userInfo = (payload) => loginEndpoint.request(auth.GET_USER, payload, 'get')


export { signIn, logOut,userInfo }
