import './App.css';
import React, {useState, useEffect} from 'react';
import Unlogged from "./router/unlogged";
import Logged from "./router/logged";
import {userInfo} from "./api/routes/auth";
import {BAD, SUCCESS} from "./helpers/response-service";

function App() {
    const [token, setToken] = useState(localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null);
    const [verify, setVerify] = useState(true);

    const logoutFunction = () => {
        localStorage.clear();
        setToken(null)
    }
    useEffect(() => {
        if(token){
            userInfo().then(response => {
                switch (response.kind) {
                    case SUCCESS :
                        break
                    case BAD :
                        setVerify(false)
                }
            }).catch(e => {
                console.log('threads error response', e.response)
            })

        }
    }, [])


    return (
        <div className="App">
            {
                token && verify ? <Logged {...{token, logoutFunction}}/> : <Unlogged {...{token, setToken,setVerify}}/>
            }
        </div>
    );
}

export default App;
