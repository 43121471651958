import React from 'react';
import './index.sass'

const DefaultButton = (props) => {
    const {text,id,type} = props;
    return (
            <button
                className='buttonStyle'
                id={id}
                type={type}
                // onClick={onClick}
            >
                <span className={text ? "button__text" : ""}>
                   {text}
                </span>
            </button>

    );
};

export default DefaultButton;
