import React from 'react'

const FilterTags = ({activeTags, handleAddTag}) => {
    return (
        <ul>
            {
                activeTags.map((tag, key) => {
                    return (
                        <li className={`unActive pointer ${tag.active ? 'active' : ''}`} onClick={() => handleAddTag(tag)} id='mfo'>{tag.name.toUpperCase()}</li>
                    )
                })
            }
            {/*<li className='unActive pointer' onClick={toggleHashtag} id='mfo'>#MFO</li>*/}
            {/*<li className='unActive active pointer' onClick={toggleHashtag}*/}
            {/*    id='casino'>#CASINO*/}
            {/*</li>*/}
            {/*<li className='unActive active pointer' onClick={toggleHashtag}*/}
            {/*    id='betting'>#BETTING*/}
            {/*</li>*/}
            {/*<li className='unActive pointer' onClick={toggleHashtag} id='other'>#OTHER*/}
            {/*</li>*/}
        </ul>
    )
}

export default FilterTags
