import Ajv from 'ajv'
import {INVALID, responseService, SUCCESS} from '../helpers/response-service'
import {errorService} from '../helpers/error-service'

const ajv = new Ajv()

const UNAUTHORIZE = 401

export const RESPONSE_TYPE_JSON = 'json'

export const RESPONSE_TYPE_BLOB = 'blob'

class Api {
    constructor (client) { //funcLogout removed
        this.client = client
        //funcLogout removed
        this.client.interceptors.response.use((response) => {
            return response
        }, function (error) {
            // Do something with response error
            if (error.response.status === UNAUTHORIZE) {
                //funcLogout removed
            }
            return Promise.reject(error.response)
        })
    }

    async request (url, data = null, method = 'get', params = null) {
        try {
            return await this.client({url, data, method, params})
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

class ApiEndpoint {
    constructor (where, api, schema) {
        this.where = where
        this.api = api
        this.schema = schema
        this.validate = ajv.compile(this.schema)
    }

    validateResult (response) {
        return this.validate(response)
    }

    transferResult (response) {
        if (this.validateResult(response)) {
            return responseService(SUCCESS, response)
        }

        console.log('%c%s', 'color: yellow;font-size: 1.2rem', `Invalid data from ${this.where}`)
        return responseService(INVALID, null)
    }

    async request (url, data = {}, method = 'get', responseType = RESPONSE_TYPE_JSON, params = {}) {
        try {
            const response = await this.api.request(url, data, method, params, responseType)
            const result = this.transferResult(response.data)
            return result
        } catch (e) {
            return errorService(e.status, e.data)
        }
    }
}

export { Api, ApiEndpoint }
