import {Route, Routes, Navigate} from "react-router-dom";
import SignIn from "../../pages/unlogged/signin";

import React from 'react';

const Unlogged = (props) => {

    const {token, setToken,setVerify} = props

    return (
            <Routes>
                <Route exact path="/signIn" element={<SignIn {...{setToken, token,setVerify}}/>} />
                <Route exact path='*' element={<Navigate to={'/signIn'} replace/>}/>
            </Routes>
    );
};

export default Unlogged;
