import React, {useState} from 'react';
import Input from "../../../components/input/DefaultInput";
import '../../../constants/variables.css'
import './index.sass'
import DefaultButton from "../../../components/button/DefaultButton";
import  {Outlet, Link, useNavigate} from "react-router-dom";


const SignIn = ({setToken,setVerify}) => {
    const [username, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();

    const login = () => {
        document.getElementById('username').style.border = 'none'
        document.getElementById('password').style.border = 'none'
        let bodyPayload = new FormData()
        bodyPayload.append('username', username.toString())
        bodyPayload.append('password', password.toString())
        fetch('//cpaapi.caltat.com/api/auth', {
            method: 'POST',
            body: bodyPayload
        }).then(response => response.json().then((result) => {
            if (result.access_token && result.access_token.toString().length > 5) {
                setToken(result.access_token)
                setVerify(true)
                console.log('new',result.access_token)
                let keys = Object.keys(result)
                keys.map(item => {
                    localStorage.setItem(item, result[item])
                })
                navigate('/main')
            } else {
                document.getElementById('username').style.border = '1px solid #ff7575'
                document.getElementById('password').style.border = '1px solid #ff7575'

            }
        }))
    }

    const onFormSubmit = e => {
        e.preventDefault();
        login()
    }


    return (
        <div className='signin__content'>
            <div className="signin__header font-size-46">
                CALTAT
            </div>
            <form className="signin__form"
                  onSubmit={onFormSubmit}
            >
                <Input placeholder='Login' id='username' onTextChange={(value) => setUserName(value)}/>
                <Input placeholder='Password' type='password' id='password' onTextChange={(value) => setPassword(value)}/>
                <div style={{marginTop: 10}} onClick={() => login()}>
                    <DefaultButton  text='SIGN IN' id='signinBtn' type="submit"/>
                </div>
            </form>
            <Outlet/>
        </div>
    );
};

export default SignIn;
