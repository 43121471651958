import React from 'react';
import './index.sass';


const Input = (props) => {
    const {onTextChange} = props

    const onValueChange = (e) => {
        onTextChange(e.target.value)
    }

    const {id,placeholder,type,error,name,disabled} = props;
    // const inputStyle = ['input', error ? "input__error" : ""].join(" ")
    return (
            <input className='defaultInput' onChange={e => onValueChange(e)} placeholder={placeholder} name={name} id={id} disabled={disabled} type={type}/>
    );
};

export default Input;
