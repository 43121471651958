import {ApiEndpoint} from "../api-service";
import {api} from "../index";
import * as stream from '../entry-points'
import {CHANGE_STREAM_NAME} from "../entry-points";

const WHERE = '/'
const schema = {}

const loginEndpoint = new ApiEndpoint(WHERE, api, schema)

const getAllStream = (payload) => loginEndpoint.request(stream.GET_ALL_STREAM + payload, null, 'get')
const createStream = (payload) => loginEndpoint.request(stream.CREATE_STREAM, payload, 'post')
const changeStreamName = (payload) => loginEndpoint.request(stream.CHANGE_STREAM_NAME, payload, 'post')

export { getAllStream, createStream,changeStreamName }
