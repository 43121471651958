import React, {useEffect, useState} from 'react';
import './index.sass';
import Copy from '../../../assets/img/copy.svg'
import DefaultInput from "../../../components/input/DefaultInput";
import {getAllOffer} from "../../../api/routes/offer";
import {BAD, SUCCESS} from "../../../helpers/response-service";
import {Outlet} from "react-router-dom";
import {getAllStream} from "../../../api/routes/stream";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FilterTags from "../../../components/UX/FilterTags";
import {userInfo} from "../../../api/routes/auth";
import Input from "../../../components/input/DefaultInput";

const filter_types = [
    {
        name: '#casino',
        word: 'casino',
        active: true
    },
    {
        name: '#mfo',
        word: 'mfo',
        active: false
    },
    {
        name: '#betting',
        word: 'betting',
        active: false
    },
    {
        name: '#other',
        word: 'other',
        active: false
    },
    {
        name: '#ru',
        word: 'ru',
        active: true
    },
    {
        name: '#ua',
        word: 'ua',
        active: false
    },
    {
        name: '#kz',
        word: 'kz',
        active: false
    },
]

const Main = (props) => {
    const {logoutFunction, token} = props
    const [user, setUser] = useState(null)
    const [offers, setOffers] = useState(null)
    const [streams, setStreams] = useState(null)
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [activeTags, setActiveTags] = useState(filter_types)
    const [tags_array, setTagsArray] = useState(null)
    const [startDate, endDate] = dateRange;
    const [searchValue, setSearch] = useState(null);
    const [sorting, setSorting] = useState(false);
    const [toggle, setToggle] = useState(true)
    const [currentId, setCurrentId] = useState('')
    const [text, setText] = useState('')
    const [toggleButton, setToggleButton] = useState('')
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
    Array.from(datePickers).forEach((el => el.childNodes[0].style.userSelect='none'))
    const logOut = () => {
        logoutFunction()
    }
    function fakeToggle() {
        console.log('kfoerko')

    }
    const getFilters = () => {
        let active = []
        const filtersArray = activeTags.some(function (tag) {
            if (tag.active === true) {
                active.push(tag.word)
            }
        })
        return active

    }
    const clickLink = event => {
        let copyText = document.getElementById(event.currentTarget.id).getElementsByTagName("span");
        // navigator.clipboard.writeText(copyText[0].innerText).then(r => null);  - not working on mobile
        document.querySelector('.copied').style.opacity = 1;
        setTimeout(function hidePopup() {
            document.querySelector('.copied').style.opacity = 0
        }, 1000);
        const element = document.createElement("textarea");
        element.value = copyText[0].innerText;
        document.body.appendChild(element)
        element.select();
        document.execCommand("copy");
        document.body.removeChild(element);
    };
    const handleAddTag = (tag) => {
        let tags_arr = []
        let tags = activeTags
        tags.map(item => {
            if (item.active) {
                tags_arr.push(item.word)
            }
            if (item.word === tag.word) {
                item.active = !item.active
            }
        })
        setActiveTags(tags)
        setTagsArray(tags_arr)
        console.log(tags_arr)
        getOffers()
    }
    const addOfferToStream = event => {
        let offer = document.getElementById(event.currentTarget.id);
        let offerId = offer.id.replace('addOffer-', '')
        let bodyPayload = new FormData();
        let imageLink = document.getElementById(`addOffer-${offerId}`).getElementsByTagName("img")[0].getAttribute("src");
        let offerName = document.getElementById(`offer-name-${offerId}`).textContent;
        bodyPayload.append('offer_id', offerId.toString())
        fetch(`//cpaapi.caltat.com/api/add-streams?offer_id=${offerId}`, {
            method: 'POST',
            body: bodyPayload,
            headers: {'Authorization': `Bearer ${token}`}
        }).then(response => response.json().then((result) => {
            if (result && result.length === 1) {
                let startDay = new Date(dateRange[0].setDate(dateRange[0].getDate())).toLocaleDateString("sv-SE");
                let endDay = new Date(dateRange[1].setDate(dateRange[1].getDate())).toLocaleDateString("sv-SE");
                let query = `?dt_from=${startDay}&dt_to=${endDay}`;
                getAllStream(query).then(response => {
                    switch (response.kind) {
                        case SUCCESS :
                            setStreams(response.data)
                            setSorting(false)
                    }
                })

                document.getElementById("newStreamImage").src = imageLink;
                document.getElementById('newStreamName').innerHTML = offerName;
                document.querySelector('.newStream').style.opacity = 1;
                setTimeout(function hidePopup() {
                    document.querySelector('.newStream').style.opacity = 0
                }, 3000)

            } else {
                document.getElementById("newStreamImage").src = imageLink;
                document.getElementById('newStreamName').innerHTML = offerName;
                document.getElementById('newStreamDescription').innerHTML = 'ERROR! Try again later';
                document.querySelector('.newStream').style.opacity = 1;
                setTimeout(function hidePopup() {
                    document.querySelector('.newStream').style.opacity = 0
                }, 3000)

            }

        }))

    };
    const sortStream = (event) => {
        let elementCurrent = document.getElementById(event.currentTarget.id);
        let elements = document.getElementsByClassName('header__item');
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].textContent.includes('↑️') || elements[i].textContent.includes('↓️')) {
                elements[i].innerHTML = elements[i].innerHTML.replace(/\↓|\↑/g, '')
            }
        }
        // let elementCurrentText = document.getElementById(event.currentTarget.textContent);
        let elementCurrentName = document.getElementById(event.currentTarget.id).id;

        let arrow = document.createTextNode(sorting ? '↑️' : '↓️');
        let copyStreams = streams;
        sorting ? copyStreams.sort((a, b) => (a[elementCurrentName] > b[elementCurrentName]) ? 1 : -1) : copyStreams.sort((a, b) => (a[elementCurrentName] < b[elementCurrentName]) ? 1 : -1)
        elementCurrent.appendChild(arrow);
        setStreams(copyStreams)

    }

    function contains(a,b) {
        let counter = 0;
        for(let i = 0; i < b.length; i++) {
            if(a.includes(b[i])) counter++;
        }
        return counter === b.length;
    }


    function getOffers() {
        const array = getFilters();
        console.log(array,'ARRAY')
        getAllOffer().then(response => {
            console.log(response.kind)
            switch (response.kind) {
                case SUCCESS :
                    let filteredOffers = [];
                    for (let i = 0; i < response.data.length; i++) {
                        if ((searchValue !== null ? contains(response.data[i]['search_string'].toLowerCase(),searchValue ) : true) && array.includes(response.data[i]['category'].toLowerCase()) && (array.includes(response.data[i]['region'].toLowerCase()) !== [] ? array.includes(response.data[i]['region'].toLowerCase()) : true)) {
                            filteredOffers.push(response.data[i])
                        }
                    }
                    setOffers(filteredOffers)
            }
        }).catch(e => {
            console.log('threads error response', e.response)
        })
        return array


    }
    useEffect(() => {
        const array = getFilters();
        if (searchValue !== null) {
            getAllOffer().then(response => {
                switch (response.kind) {
                    case SUCCESS :
                        let filteredSearch = [];
                        for (let i = 0; i < response.data.length; i++) {
                            //  if (contains(response.data[i]['search_string'].toLowerCase(),searchValue) && array.includes(response.data[i]['category'].toLowerCase())) {
                                if (contains(response.data[i]['search_string'].toLowerCase(),searchValue) && array.includes(response.data[i]['category'].toLowerCase()) && (array.includes(response.data[i]['region'].toLowerCase()) !== [] ? array.includes(response.data[i]['region'].toLowerCase()) : true)) {
                                filteredSearch.push(response.data[i])
                            }
                        }
                        setOffers(filteredSearch)
                }
            }).catch(e => {
                console.log('threads error response', e.response)
            })
        }
    }, [searchValue])
    useEffect(() => {
    }, [activeTags])
    useEffect(() => {
    }, [tags_array])
    useEffect(() => {
    }, [currentId, text])
    useEffect(() => {
        getOffers()
        userInfo().then(response => {
            switch (response.kind) {
                case SUCCESS :
                    setUser(response.data)
            }
        }).catch(e => {
            console.log('threads error response', e.response)
        })
    }, [])
    useEffect(() => {
        if (endDate) {
            let startDay = new Date(dateRange[0].setDate(dateRange[0].getDate())).toLocaleDateString("sv-SE");
            let endDay = new Date(dateRange[1].setDate(dateRange[1].getDate())).toLocaleDateString("sv-SE");
            let query = `?dt_from=${startDay}&dt_to=${endDay}`;
            getAllStream(query).then(response => {
                switch (response.kind) {
                    case SUCCESS :
                        setStreams(response.data)
                }
            })
        }
    }, [dateRange])


    function handleChange(event) {
        setText(event.target.value);
    }

    function changeToggle(event) {
        setCurrentId(event.target.parentNode.nextElementSibling.id)
        setToggle(false)
    }

    function changeRange(period, event) {
        document.querySelectorAll(".calendar-preset").forEach(function (item) {
            item.style.color = '#CCCCCC';
            });
        event.target.style.color = '#FFFFFF';
        if (period === 'T') {
            setDateRange([new Date(), new Date()])
        }
        if (period === 'W') {
            setDateRange([new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()])
        }
        if (period === 'M') {
            let d = new Date();
            let newMonth = d.getMonth() - 1;
            if (newMonth < 0) {
                newMonth += 12;
                d.setFullYear(d.getFullYear() - 1);
            }
            d.setMonth(newMonth);
            setDateRange([d, new Date()])
        }


    }

    function editStreamName(event) {
        let bodyPayload = new FormData();
        let idCutted = currentId.substring(1);
        console.log(idCutted, text);
        bodyPayload.append('stream_id', idCutted.toString());
        fetch(`//cpaapi.caltat.com/api/change-stream-name?stream_id=${idCutted}&stream_name=${text}`, {
            method: 'POST',
            body: bodyPayload,
            headers: {'Authorization': `Bearer ${token}`}
        }).then(response => response.json().then((result) => {
                if (result && result.length === 1) {
                    console.log('done')
                    let startDay = new Date(dateRange[0].setDate(dateRange[0].getDate())).toLocaleDateString("sv-SE");
                    let endDay = new Date(dateRange[1].setDate(dateRange[1].getDate())).toLocaleDateString("sv-SE");
                    let query = `?dt_from=${startDay}&dt_to=${endDay}`;
                    getAllStream(query).then(response => {
                            switch (response.kind) {
                                case SUCCESS :
                                    setStreams(response.data)
                            }
                        }
                    )

                }
            }
        ))
    }

    return (
        <div>
            <div className="pop-up copied">
                <span>link copied!</span>
            </div>
            <div className="pop-up newStream">
                <div className="popup__item-logo">
                    <img src='' id='newStreamImage'/>
                </div>

                <div className="newStreamText">
                    <p id='newStreamName'></p>
                    <span id='newStreamDescription'>New offer added! <br/> Click to create stream</span>
                </div>

            </div>
            <div className="container">
                <div className="header">
                    <div className="signin__header main__header">

                       <span>CALTAT</span>
                        <div className="header__balance">
                            <div className="header__title">PARTNERS</div>
                            <div className="header__title" style={{color:'#4B4B4B'}}>BALANCE: ${(user ? user.balance : '0')}</div>
                        </div>

                    </div>
                    <div className="header__user">
                        <div
                            className="header__username pointer">  {(user ? (user.user_name) : 'No name')}</div>
                        <div className="header__logout pointer" onClick={logOut}> LOG OUT</div>
                    </div>
                </div>
                <div className="content">
                    <div className="mobile-calendar">
                        <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            dateFormat="dd.MM.yy"
                            endDate={endDate}
                            onChange={(update) => {
                                setDateRange(update);
                            }}
                            isClearable={true}
                        >
                            <div style={{color: "red"}}>Yesterday</div>
                            <div style={{color: "red"}}>Today</div>
                            <div style={{color: "red"}}>Week</div>
                            <div style={{color: "red"}}>Month</div>
                        </DatePicker>
                    </div>
                    <div className="columns">
                        <div className="column-1-2">
                            <div className="column-1">
                    <span className='vertical__header'>
                         ━━━━━━━━━━━━━━━━━━━━━━ STREAMS
                    </span>

                            </div>

                            <div className="column-2">


                                <table cellSpacing="0" cellPadding="0" className='streams__table'>
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th>
                                            <div className="header__item-clicks header__item" id='clicks'
                                                 onClick={(event) =>
                                                     [setSorting(!sorting), sortStream(event)]
                                                 }><span>CLI</span><span className='mobileHidden'>CKS</span></div>

                                        </th>
                                        <th>
                                            <div className="header__item-reg-cr header__item" id='cr_reg'
                                                 onClick={(event) => [setSorting(!sorting), sortStream(event)]}
                                            >REG <span className='mobileHidden'>/ CR</span></div>
                                        </th>
                                        <th>
                                            <div className="header__item-dep-cr  header__item" id='cr_dep'
                                                 onClick={(event) => [setSorting(!sorting), sortStream(event)]}
                                            >DEP <span className='mobileHidden'>/ CR</span></div>
                                        </th>
                                        <th>
                                            <div className="header__item-income header__item" id='income'
                                                 onClick={(event) => [setSorting(!sorting), sortStream(event)]}
                                            >INC<span className='mobileHidden'>OME</span></div>

                                        </th>
                                        <th>
                                            <div className="header__item-epc header__item" id='epc'
                                                 onClick={(event) => [setSorting(!sorting), sortStream(event)]}
                                            >EPC
                                            </div>

                                        </th>
                                        <th>
                                            <div className="header__item-dates">
                                                <DatePicker
                                                    customInput={<input onClick={() => fakeToggle()}/>}
                                                    selectsRange={true}
                                                    startDate={startDate}
                                                    dateFormat="dd.MM.yy"
                                                    endDate={endDate}
                                                    onChange={(update) => {
                                                        setDateRange(update);
                                                    }}
                                                    isClearable={true}


                                                >
                                                    <div style={{marginBottom: 10}}>
                                                        <span className='calendar-preset'
                                                              style={{color: "#cccccc", marginRight: 45}}
                                                              onClick={(event) => changeRange('T', event)}>Today</span>
                                                        <span className='calendar-preset'
                                                              style={{color: "#cccccc", marginRight: 45}}
                                                              onClick={(event) => changeRange('W', event)}>Week</span>
                                                        <span className='calendar-preset'
                                                              style={{color: "#cccccc"}}
                                                              onClick={(event) => changeRange('M', event)}>Month</span>
                                                    </div>
                                                </DatePicker>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        streams ? streams.map((stream, key) => {
                                                return (
                                                    <>
                                                        <tr className='streams__item' key={stream.stream_id}>
                                                            <td>
                                                                <div className="streams__item-logo">
                                                                    <img
                                                                        src={stream.offer.picture_link}/>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="streams__item-name">
                                                                    {!toggle && currentId === stream.stream_id ?
                                                                        (
                                                                            <input
                                                                                type='text'
                                                                                style={{
                                                                                    height: '20px',
                                                                                    width: '90%',
                                                                                    paddingLeft: '5px'
                                                                                }}
                                                                                defaultValue={stream.stream_name}
                                                                                onChange={(event) => {
                                                                                    handleChange(event)
                                                                                }}
                                                                                onKeyDown={(event) => {
                                                                                    if (event.key === 'Enter' || event.key === 'Escape') {
                                                                                        setToggle(true)
                                                                                        event.preventDefault()
                                                                                        event.stopPropagation()
                                                                                        if (text !== '' && text !== null) {
                                                                                            editStreamName(event)
                                                                                        }
                                                                                        setText(null)

                                                                                    }
                                                                                }}
                                                                            />
                                                                        ) :
                                                                        (
                                                                            <div
                                                                                data-title={stream.stream_name}
                                                                                className="streams__item-title"
                                                                                onDoubleClick={(event) => {
                                                                                    changeToggle(event)
                                                                                }}
                                                                            ><span>{stream.stream_name}</span></div>
                                                                        )
                                                                    }
                                                                    <div
                                                                        className="streams__item-number"
                                                                        id={stream.stream_id}>{stream.stream_id}
                                                                    </div>

                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className='streams__item-date'>
                                                                    <div style={{display: "flex"}}>
                                                                        <span className="streams__item-dm">{new Date(stream.created).getDate() < 10 ? '0' + new Date(stream.created).getDate() : new Date(stream.created).getDate()}.{new Date(stream.created).getMonth() + 1 < 10 ? '0' + (new Date(stream.created).getMonth() + 1) : new Date(stream.created).getMonth() + 1}.</span>
                                                                        <span className="streams__item-y">{new Date(stream.created).getFullYear()}</span>
                                                                    </div>

                                                                    <span className="streams__item-y">{stream.offer.offer_name} {stream.offer.region}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div
                                                                    className="streams__item-clicks">{stream.clicks === null ? 0 : stream.clicks}</div>
                                                            </td>
                                                            <td>
                                                                <div className="streams__item-reg-cr">
                                                                <span
                                                                    className="streams__item-reg">{stream.reg === null ? 0 : stream.reg} / </span>
                                                                    <span
                                                                        className="streams__item-cr">{stream.cr_reg === null ? 0 : Math.floor(stream.cr_reg)}%</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="streams__item-dep-cr">
                                                                <span
                                                                    className="streams__item-dep">{stream.dep === null ? 0 : stream.dep} / </span>
                                                                    <span
                                                                        className="streams__item-cr">{stream.cr_dep === null ? 0 : Math.floor(stream.cr_dep)}%</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="streams__item-income">
                                                                    ${stream.income === null ? 0 : stream.income}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="streams__item-epc">
                                                                    ${stream.epc === null ? 0 : stream.epc}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="streams__item-link">
                                                                    <div className="streams__item-link-container"
                                                                         id={`streams__item-link-${stream.stream_id}`}
                                                                         onClick={clickLink}>
                                                                        <div className="streams__item-link-copy-pic">
                                                                            <img src={Copy}/>
                                                                        </div>
                                                                        <span
                                                                            className="streams__item-link-copy-link">{stream.unq_link === null ? 'Link not found' : stream.unq_link}</span>
                                                                    </div>

                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <div key={stream.stream_id + key} style={{height: 10}}></div>
                                                    </>
                                                )
                                            })
                                            :
                                            null}
                                    </tbody>
                                </table>
                                <div className="column-2__header">
                                </div>
                            </div>
                        </div>
                        <div className="column-3-4">
                            <div className="column-3">
                     <span className='vertical__header'>
                           ━━━━━━━━━━━━━━━━━━━━━━ OFFERS
                    </span>
                            </div>
                            <div className="column-4">
                                <div className="filters">
                                    <div className="hashtags">
                                        <FilterTags {...{activeTags, handleAddTag, tags_array}}/>
                                    </div>
                                    <div className="search pointer">
                                        <DefaultInput type='search' placeholder='Search...'
                                                      onTextChange={(value) => setSearch(value.toLowerCase().split(/(\s+)/).filter( function(e) { return e.trim().length > 0; }))}/>
                                    </div>
                                </div>
                                <div className="offers__items">
                                    {
                                        offers ?
                                            offers.map(offer => {
                                                return (
                                                    <div className="offers__item" key={offer.offer_id}>
                                                        <div className="offers__item-container">
                                                            <div className="offers__item-logo-container">
                                                                <div className="offers__item-logo"
                                                                     onClick={addOfferToStream}
                                                                     id={'addOffer-' + offer.offer_id}
                                                                >
                                                                    <img src={offer.picture_link}/>
                                                                </div>
                                                                <div className="addOffer"
                                                                     onClick={addOfferToStream}
                                                                     id={'addOffer-' + offer.offer_id}
                                                                >+
                                                                </div>
                                                            </div>

                                                            <div className="offers__item-text">
                                                                <div className="offers__item-name">
                                                                    <div
                                                                        className="offers__item-description">{offer.description}</div>
                                                                    <span
                                                                        className="offers__item-title"
                                                                        id={'offer-name-' + offer.offer_id}> {offer.offer_name}</span>
                                                                </div>
                                                                <div className="offers__item-info">
                                                                    <div className="offers__item-type">
                                                <span
                                                    className="offers__item-country offers__item-hashtag">{offer.region} {offer.category}</span>
                                                                    </div>
                                                                    <div className='offers__item-stats'>
                                                                        <div
                                                                            className="offers__item-payout">{'Payout ' + offer.payout + '$'}</div>
                                                                        <div
                                                                            className="offers__item-baseline">{offer.baseline !== null ? 'Baseline ' + offer.baseline + '$' : 'Mindep ' + offer.mindep + '$'}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })

                                            : null
                                    }
                                </div>

                            </div>
                            <div className="down-gradient"></div>

                        </div>

                    </div>


                </div>
            </div>

            <Outlet/>
        </div>
    );
};

export default Main;
