import {ApiEndpoint} from "../api-service";
import {api} from "../index";
import * as offer from '../entry-points'

const WHERE = '/'
const schema = {}

const loginEndpoint = new ApiEndpoint(WHERE, api, schema)

const getAllOffer = () => loginEndpoint.request(offer.GET_ALL_OFFER, null, 'get')

export { getAllOffer }
