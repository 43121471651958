import { Route, Routes, Navigate } from "react-router-dom";
import Main from "../../pages/logged/main";

import React from 'react';

const Logged = (props) => {

    const {
        token, logoutFunction
    } = props

    return (
            <Routes>
                <Route path={'/main'} element={<Main {...{token, logoutFunction}}/>} />
                <Route path={'*'} element={<Navigate to="/main" replace />}/>

            </Routes>
    );
};

export default Logged;
